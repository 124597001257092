@mixin create-hotel-row_private {
  &_hotel {
    @include make-one-fifth;
        // background-color: pink;
  }
  &_from,
  &_to {
    @include make-two-fifth;
  }
  &_duration {
    @include make-one-fifth;
  }
}

@mixin create-hotel-detail-row_private {
  &__hotel,
  &__from,
  &__to,
  &__duration,
  &__price {
    padding-top: $base-padding*0.5;
  }
  &__hotel,
  &__duration {
    text-align: center;
  }
  &__from {
    text-align: left;
  }
  &__to {
    text-align: right;
  }
  &__from,
  &__to {
    &_airport {
      font-size: $font-size-h6;
    }
    &_location {
      text-transform: uppercase;

      // font-size: $font-size-h4;
      color: $brand-color-2;
    }
    &_time,
    &_date {
      font-family: $font-family-number;

      // font-weight: 400;
    }
    &_date {
      font-size: .875rem;
      color: $brand-color-5;
    }
    &_time {
      font-size: $font-size-number-small;
      color: $brand-color-2;
      line-height: 1.125rem;
    }
  }
  &__duration {
    &_time {
      font-size: $font-size-number-small;
      font-family: $font-family-number;
      font-weight: 400;
      line-height: 1.125rem;
      color: lighten($brand-color-1, 20);
      position: relative;
      text-transform: lowercase;
      &:before,
      &:after {
        width: 60px;
        content: "";
        height: 1px;
        background-color: lighten($brand-color-5, 30);
        position: absolute;
        top: 50%;
      }
      &:before {
        right: 100%;
      }
      &:after {
        left: 100%;
      }
    }
    &_wrapper {
      @include clearfix;
      @include make-four-fifth;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@mixin  create-hotel-row { 
	@include create-hotel-row_private; 
}
@mixin  create-hotel-detail-row { 
	@include create-hotel-detail-row_private; 
}