.rate-category {
    &-card {
        @include travel-card;
        &__table {
            font-family: $font-family-number;
            width: 100%;
          
            td {
                border-bottom: 1px solid lighten($brand-color-5, 20);
                padding-top: 0.625rem;
                padding-bottom: 0.3125rem;
                //padding-left: 0.625rem;
                //padding-right: 0.625rem;
                font-weight: 600;
            }
        }
    }
    &__footer {}
}