@use "sass:math";

@mixin create-guest-occupancy-card_private {
  .guest-occupancy {
    @include create-main-wrapper;
    @include create-row;
    @include create-row-card;
    @include create-row-card-header;
    @include create-card-content;
    @include create-row-card-footer;
    @include create-hotel-row;
    &_card {
      box-shadow: $row-shadow-color;
    }
    &__content {
      @include clearfix;
      width: 100%;
      margin-top: $base-margin*0.5;
      margin-bottom: $base-margin*0.5;
      td {
        width: 15%;
        font-weight: 400;
        font-size: 0.75rem;
        padding: math.div($base-padding, 6);
        /*&:last-child {
        text-align: right;
      }*/
        padding-bottom: 0.9375rem;
      }
      &_icon{
        color: $brand-color-31;
      }
      &_highlight{
		color: $brand-color-1;
	  }
    }
    &__title {
      text-transform: uppercase;
      color: $brand-color-2;
      padding-top: 0.9375rem;
      padding-bottom: 0.375rem;
      padding-right: 0.375rem;
      padding-left: 0;
      >span {
        color: $brand-color-2;
        float: right;
        text-transform: uppercase;
        font-size: 0.625rem;
      }
    }
    &_total {
      background-color: $brand-color-4;
      border-top: 1px solid lighten($brand-color-5, 20);
      border-bottom: 1px solid lighten($brand-color-5, 20);
      td {
        font-weight: 700;
      }
    }
    &__info {
      >table {
        width: 100%;
      }
    }
    &__header {
      background-color: $brand-color-4;
      text-transform: uppercase;
      th {
        width: 15%;
        font-weight: 400;
        font-size: 0.75rem;
        padding: math.div($base-padding, 6);
        /*&:last-child {
        text-align: right;
      }*/
      }
      tr {
        border-bottom: 0.125rem solid $brand-color-4;
      }
    }
    &__notification{
		color: $brand-color-1;
	}
  }
}

@mixin  create-guest-occupancy-card { 
	@include create-guest-occupancy-card_private; 
}