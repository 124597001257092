@mixin make-no-of-nights-card_private {
  .no-of-nights {
    &-card {
      @include travel-card;
      &__table {
        font-family: $font-family-number;
        width: 100%;
        &-row {

        }
        tr {
          &:last-child {
            background-color: $brand-color-4;
          }
        }
        td {
          border-bottom: 1px solid lighten($brand-color-5, 20);
          padding-top: 0.625rem;
          padding-bottom: 0.3125rem;
          font-weight: 600;
          &:first-child {

          }
          &:last-child {
            text-align: right;
          }
        }
      }
    }
    &__footer {

    }
  }
}

@mixin  make-no-of-nights-card { 
	@include make-no-of-nights-card_private; 
}