%pax-details {
  border: 1px solid $gray-lighting;
  border-radius: 4px;
  margin-bottom: .5rem;
}

%pax-details-heading {
  background-color: $gray-lighter;
  color: $brand-color-2;
  font-size: 1rem;
  padding: 0 .25rem;
}

%pax-details-container {
  @include clearfix;
  width: 100%;
  font-size: 1rem;
}

.hub-surcharge {
  @include make-one-half;
  float: right;

  &__adult-details {
    @extend %pax-details;
    
    &_heading {
      @extend %pax-details-heading;
    }
    
    &_container {
      @extend %pax-details-container;
    }
  }
  
  &__child-details {
    @extend %pax-details;
    
    &_heading {
      @extend %pax-details-heading;
    }

    &_container {
      @extend %pax-details-container;
    }
  }

  &__total-amount-container {
    @include clearfix;
    border: 1px solid $gray-lighting;
    border-radius: 4px;
    width: 100%;
    font-size: 1rem;
    padding: 0 .25rem;

    &_heading {
      float: left;
      color: $brand-color-2;
    }
    &_total-amount {
      float: right;

      .total-amount {
        &__currency {
          color: $brand-color-1;
          font-size: .75rem;
        }
        &__value {
          font-family: $font-family-number;
        }
      }
    }
  }
}

.pax-details-container {
  &__pax-count, &__multiply-sign, &__hub-surcharge-amount {
    float: left;
  }

  &__pax-count {
    width: 20%;
    padding: 0 .25rem;
  }

  &__multiply-sign {
    width: 5%;
  }

  &__hub-surcharge-amount {
    width: 20%;
    font-family: $font-family-number;
    padding: 0 0.5rem;
  }

  &__pax-total-amount {
    float: right;
    padding-right: .25rem;

    &_currency {
      color: $brand-color-1;
      font-size: .75rem;
    }
    &_amount {
      font-family: $font-family-number;
    }
  }
}