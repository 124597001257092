@use "sass:math";

.package__pax-info {
  @include create-pax-info;

  &_footer-row {
  	@include clearfix();
	padding-top: .9375rem;
  	padding-bottom: .9375rem;
  }   	
}
.pax-info{
  @include create-search-form;
}
.view__cart {
  float: right;
  padding: math.div($base-padding, 6);
}
.package__pax-info_main {
  margin-left: 0;
}
