// Add no-of-nights card scss
@use "sass:math";

@include make-no-of-nights-card;

// Add guest occupancy-card scss
@include create-guest-occupancy-card;
.package__review {
  @include create-main-wrapper;
  @include create-row;
  @include create-row-card;
  @include create-row-card-header;
  @include create-card-content;
  @include create-row-card-footer;
  @include create-package-row;
  &_main {
    padding-top: $base-padding*0.5;
    .content {
      padding-left: $base-padding*0.5;
      padding-right: 0;
    }
    .alert__message--info {
      //margin-right: 0.9375rem;
      //margin-left: 0.9375rem;
    }
  }
  &_card {
    box-shadow: $row-shadow-color;
    &_title {
      text-transform: uppercase;
      color: $brand-color-2;
      padding: 0.9375rem 0 0.375rem 0.375rem;
    }
    &_header {
      background-color: $brand-color-4;
      text-transform: uppercase;
    }
     @include create-review-nav;
  }
  &_progress {
    @include make-row;
    max-width: 75%;
    counter-reset: step -1;
    margin-bottom: $base-margin*0.5;
    > li {
      @include make-one-fifth;
      text-align: center;
      position: relative;
      text-transform: uppercase;
      font-size: $font-size-h6;
      > span {
        display: inline-block;
        color: $brand-color-2;
        &:before {
          content: counter(step);
          counter-increment: step;
          width: 30px;
          line-height: 30px;
          border-radius: 100%;
          background-color: $light;
          display: block;
          font-size: 1rem;
          margin: 0 auto;
          color: lighten($brand-color-5, 20);
        }
        &:after {
          content: '';
          width: 100%;
          height: 1px;
          background-color: lighten($brand-color-5, 20);
          position: absolute;
          left: -50%;
          top: 15px;
          z-index: -1;
        }
        &.active {
          color: $dark;
          &:before {
            background-color: $brand-color-2;
            color: $light;
          }
        }
      }
      &:first-child > span {
        color: inherit;
        cursor: pointer;
        &:before {
          content: '<';
          background-color: lighten($brand-color-5, 20);
          color: $light;
        }
        &:after {
          content: none;
        }
        &:hover:before {
          background-color: $brand-color-1;
        }
        &:hover {
          color: $brand-color-1;
        }
      }
    }
  }
  &_name {

    // box-shadow: $row-shadow-color;
    background-color: $light;
    margin-left: $base-margin*0.5;
    margin-right: $base-margin*0.5;
    margin-bottom: $base-margin*0.5;
    >div {
      color: $brand-color-2;
      font-size: $font-size-h3;
      padding: math.div($base-padding, 3);
    }
  }
}
  .package__remark_heading,
.package__inclusion_heading,
.package__exclusion_heading,
.package__cancellation_heading {
  font-size: $font-size-h4;
  color: $brand-color-2;
  padding-top: $base-padding*0.5;
  padding-bottom: math.div($base-padding, 3);
}
